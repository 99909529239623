import { CountryCode } from 'interfaces/Country'
import { LanguageCode } from 'interfaces/Language'
import config, { Environment } from 'utils/config'

export function countryToLanguageCode(countryCode: CountryCode) {
    switch (countryCode) {
        case CountryCode.Germany:
        case CountryCode.Switzerland:
        case CountryCode.Austria:
            return LanguageCode.German
        case CountryCode.France:
            return LanguageCode.French
        case CountryCode.Poland:
            return LanguageCode.Polish
        case CountryCode.Italy:
            return LanguageCode.Italian
        case CountryCode.UnitedKingdom:
            return LanguageCode.BritishEnglish
        case CountryCode.UnitedStates:
            return LanguageCode.AmericanEnglish
        case CountryCode.Spain:
            return LanguageCode.Spanish
        case CountryCode.Colombia:
            return LanguageCode.SpanishColombia
        case CountryCode.Turkey:
            return LanguageCode.Turkish
    }

    return null
}

export function languageToCountryCode(languageCode: LanguageCode | null) {
    switch (languageCode) {
        case LanguageCode.AmericanEnglish:
            return CountryCode.UnitedStates
        case LanguageCode.Catalan:
        case LanguageCode.Spanish:
            return CountryCode.Spain
        case LanguageCode.French:
            return CountryCode.France
        case LanguageCode.BritishEnglish:
            return CountryCode.UnitedKingdom
        case LanguageCode.Polish:
            return CountryCode.Poland
        case LanguageCode.Italian:
            return CountryCode.Italy
        case LanguageCode.SpanishColombia:
            return CountryCode.Colombia
        case LanguageCode.Turkish:
            return CountryCode.Turkey
        default:
            return CountryCode.Germany
    }
}

export function countryToEnglishName(countryCode: CountryCode) {
    switch (countryCode) {
        case CountryCode.Germany:
            return 'Germany'
        case CountryCode.Switzerland:
            return 'Switzerland'
        case CountryCode.Austria:
            return 'Austria'
        case CountryCode.France:
            return 'France'
        case CountryCode.Poland:
            return 'Poland'
        case CountryCode.Italy:
            return 'Italy'
        case CountryCode.UnitedKingdom:
            return 'United Kingdom'
        case CountryCode.UnitedStates:
            return 'United States'
        case CountryCode.Spain:
            return 'Spain'
        case CountryCode.Turkey:
            return 'Turkey'
    }

    return `Unknown country (${countryCode})`
}

export function countryCodeToLiveDomain(countryCode: CountryCode) {
    if (config.getEnvironment() === Environment.Prod) {
        switch (countryCode) {
            case CountryCode.Germany:
            case CountryCode.Switzerland:
            case CountryCode.Austria:
                return 'knowunity.de'
            case CountryCode.France:
                return 'knowunity.fr'
            case CountryCode.Poland:
                return 'knowunity.pl'
            case CountryCode.Italy:
                return 'knowunity.it'
            case CountryCode.UnitedKingdom:
                return 'knowunity.co.uk'
            case CountryCode.UnitedStates:
                return 'knowunity.com'
            case CountryCode.Spain:
                return 'knowunity.es'
            case CountryCode.Turkey:
                return 'knowunity.com.tr'
        }
    } else {
        switch (countryCode) {
            case CountryCode.Germany:
            case CountryCode.Switzerland:
            case CountryCode.Austria:
                return 'de.knowunity.dev'
            case CountryCode.France:
                return 'fr.knowunity.dev'
            case CountryCode.Poland:
                return 'pl.knowunity.dev'
            case CountryCode.Italy:
                return 'it.knowunity.dev'
            case CountryCode.UnitedKingdom:
                return 'gb.knowunity.dev'
            case CountryCode.UnitedStates:
                return 'us.knowunity.dev'
            case CountryCode.Spain:
                return 'es.knowunity.dev'
            case CountryCode.Turkey:
                return 'tr.knowunity.dev'
        }
    }

    return `Unknown country (${countryCode})`
}
