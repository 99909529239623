import { Country } from './Country'
import { Grade } from './Grade'
import { Region } from './Region'
import { SchoolType } from './SchoolType'
import { Subject } from './Subject'

export enum UserTargetingTypes {
    UserList = 'USER_LIST',
    Attributes = 'ATTRIBUTES',
}

export enum UserTargetingOutOfSchoolStatus {
    Include = 'INCLUDE',
    Exclude = 'EXCLUDE',
}

export enum PersonaUserTargeting {
    ContentUser = 'CONTENT_USER',
    AiUser = 'AI_USER',
    ZombieUserA = 'ZOMBIE_A_USER',
    ZombieUserB = 'ZOMBIE_B_USER',
}

export interface UserTargeting {
    id: number
    grades: Grade[]
    regions: Region[]
    issueSubjects: Subject[]
    schoolTypes: SchoolType[]
    countries: Country[]
    locationPrefixes: {
        prefix: string
    }[]
    userCount: number
    isKnower: boolean | null
    type: UserTargetingTypes
    daysSinceUserCreation: number | null
    includeUsersWithoutSchoolType: boolean
    includeUsersWithoutGrade: boolean
    maxLastActiveSinceDaysAgo: number | null
    minLastActiveSinceDaysAgo: number | null
    outOfSchoolStatus: UserTargetingOutOfSchoolStatus
    persona: PersonaUserTargeting | null
}

export interface UserTargetingRequest {
    gradeIds: number[] | null
    regionIds: number[] | null
    schoolTypeIds: number[] | null
    countryIds: number[] | null
    locationPrefixes: string[] | null
    isKnower: boolean | null
    issueSubjectIds: number[] | null
    type: UserTargetingTypes
    daysSinceUserCreation: number | null
    maxLastActiveSinceDaysAgo: number | null
    minLastActiveSinceDaysAgo: number | null
    includeUsersWithoutSchoolType?: boolean
    includeUsersWithoutGrade?: boolean
    outOfSchoolStatus: UserTargetingOutOfSchoolStatus | null
    persona: PersonaUserTargeting | null
}
