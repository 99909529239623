import { CountryCode } from 'interfaces/Country'
import { LanguageCode } from 'interfaces/Language'

export function languageCodeToEnglishName(languageCode: LanguageCode) {
    switch (languageCode) {
        case LanguageCode.German:
            return 'German'
        case LanguageCode.BritishEnglish:
            return 'English (UK)'
        case LanguageCode.French:
            return 'French'
        case LanguageCode.Polish:
            return 'Polish'
        case LanguageCode.Italian:
            return 'Italian'
        case LanguageCode.AmericanEnglish:
            return 'English (US)'
        case LanguageCode.Spanish:
            return 'Spanish'
        case LanguageCode.Catalan:
            return 'Catalan'
        case LanguageCode.SpanishColombia:
            return 'Colombia'
        case LanguageCode.Turkish:
            return 'Turkish'
    }

    return `Unknown country (${languageCode})`
}

export function languageCodeToCountryCode(languageCode: LanguageCode) {
    switch (languageCode) {
        case LanguageCode.German:
            return CountryCode.Germany
        case LanguageCode.BritishEnglish:
            return CountryCode.UnitedKingdom
        case LanguageCode.French:
            return CountryCode.France
        case LanguageCode.Polish:
            return CountryCode.Poland
        case LanguageCode.Italian:
            return CountryCode.Italy
        case LanguageCode.AmericanEnglish:
            return CountryCode.UnitedStates
        case LanguageCode.Spanish:
            return CountryCode.Spain
        case LanguageCode.Catalan:
            return CountryCode.Spain
        case LanguageCode.SpanishColombia:
            return CountryCode.Colombia
        case LanguageCode.Turkish:
            return CountryCode.Turkey
    }

    return `Unknown country (${languageCode})`
}
