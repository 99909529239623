import React from 'react'
import Stack from 'components/elements/Stack'
import { Body1 } from 'components/elements/Text'
import { EmailCategories } from 'interfaces/ManualEmails'
import { toast } from 'react-toastify'

export const handleClickRateHelpersCheck = (html: string) => {
    if (!html) return

    const linkTagsMatches = html.match(/<a[^>]*?>/g)
    if (!linkTagsMatches?.length) return
    const inproperLinks: string[] = []
    linkTagsMatches.forEach((match) => {
        const hrefs = match.match(/href="([^"]*)/)
        if (
            !hrefs?.length ||
            hrefs[1].startsWith('https://bit') ||
            hrefs[1].startsWith('mailto:') ||
            hrefs[1].startsWith('https://a.knowunity') ||
            hrefs[1].startsWith('{{unsubscribe')
        )
            return
        inproperLinks.push(hrefs[1])
    })

    if (!inproperLinks.length) return
    toast.warning(
        <Stack gutter={10}>
            <Body1>{`The following links:`}</Body1>

            {inproperLinks.map((href, i) => (
                <span key={`links-${i}`}>{`${i + 1}: ${href}`}</span>
            ))}
            <Body1>{`are not using bit.ly shortener, so it can't be tracked properly!`}</Body1>
        </Stack>,
        {
            autoClose: false,
            progress: 0,
            closeOnClick: true,
            pauseOnHover: true,
            hideProgressBar: false,
        }
    )
}

export const getDefaultEmailBody = () => ({
    counters: {
        u_column: 13,
        u_row: 10,
        u_content_text: 19,
        u_content_image: 12,
        u_content_heading: 15,
        u_content_menu: 1,
        u_content_button: 2,
    },
    body: {
        id: '6VxQbgWiJY',
        rows: [
            {
                id: 'YdjeAb25KL',
                cells: [1],
                columns: [
                    {
                        id: 'nhVldnhyx2',
                        contents: [
                            {
                                id: 'EGmNytK_EN',
                                type: 'image',
                                values: {
                                    containerPadding: '10px',
                                    anchor: '',
                                    src: {
                                        url: 'https://assets.unlayer.com/projects/0/1725892513064-Logo_black.png',
                                        width: 240,
                                        height: 116,
                                        contentType: 'image/png',
                                        size: 2152,
                                    },
                                    textAlign: 'center',
                                    altText: '',
                                    action: { name: 'web', values: { href: '', target: '_blank' } },
                                    hideDesktop: false,
                                    displayCondition: null,
                                    _meta: { htmlID: 'u_content_image_1', htmlClassNames: 'u_content_image' },
                                    selectable: true,
                                    draggable: true,
                                    duplicatable: true,
                                    deletable: true,
                                    hideable: true,
                                    pending: false,
                                },
                            },
                        ],
                        values: {
                            backgroundColor: '',
                            padding: '0px',
                            border: {},
                            borderRadius: '0px',
                            _meta: { htmlID: 'u_column_2', htmlClassNames: 'u_column' },
                        },
                    },
                ],
                values: {
                    displayCondition: null,
                    columns: false,
                    backgroundColor: '',
                    columnsBackgroundColor: '',
                    backgroundImage: {
                        url: '',
                        fullWidth: true,
                        repeat: 'no-repeat',
                        size: 'custom',
                        position: 'center',
                    },
                    padding: '0px',
                    anchor: '',
                    hideDesktop: false,
                    _meta: { htmlID: 'u_row_2', htmlClassNames: 'u_row' },
                    selectable: true,
                    draggable: true,
                    duplicatable: true,
                    deletable: true,
                    hideable: true,
                },
            },
            {
                id: 'twOEjkqCvc',
                cells: [1],
                columns: [
                    {
                        id: 'cv7w98Vaty',
                        contents: [
                            {
                                id: 'q1DU1rCClR',
                                type: 'heading',
                                values: {
                                    containerPadding: '10px',
                                    anchor: '',
                                    headingType: 'h1',
                                    fontWeight: 700,
                                    fontSize: '20px',
                                    fontFamily: 'Inter',
                                    textAlign: 'center',
                                    lineHeight: '105%',
                                    linkStyle: {
                                        inherit: true,
                                        linkColor: '#0000ee',
                                        linkHoverColor: '#0000ee',
                                        linkUnderline: true,
                                        linkHoverUnderline: true,
                                    },
                                    hideDesktop: false,
                                    displayCondition: null,
                                    _meta: { htmlID: 'u_content_heading_2', htmlClassNames: 'u_content_heading' },
                                    selectable: true,
                                    draggable: true,
                                    duplicatable: true,
                                    deletable: true,
                                    hideable: true,
                                    text: '<span style="line-height: 21px;">BACK TO SCHOOL</span>',
                                    _languages: {},
                                },
                            },
                            {
                                id: 'fzpO_Siy3Q',
                                type: 'text',
                                values: {
                                    containerPadding: '10px',
                                    anchor: '',
                                    fontWeight: 700,
                                    fontSize: '82px',
                                    textAlign: 'center',
                                    lineHeight: '100%',
                                    linkStyle: {
                                        inherit: true,
                                        linkColor: '#0000ee',
                                        linkHoverColor: '#0000ee',
                                        linkUnderline: true,
                                        linkHoverUnderline: true,
                                    },
                                    hideDesktop: false,
                                    displayCondition: null,
                                    _meta: { htmlID: 'u_content_text_2', htmlClassNames: 'u_content_text' },
                                    selectable: true,
                                    draggable: true,
                                    duplicatable: true,
                                    deletable: true,
                                    hideable: true,
                                    text: '<p style="line-height: 100%;">5 tips for better grades</p>',
                                    _languages: {},
                                    _override: { mobile: { fontSize: '62px' } },
                                },
                            },
                            {
                                id: 'yAXNWA5mQO',
                                type: 'heading',
                                values: {
                                    containerPadding: '10px',
                                    anchor: '',
                                    headingType: 'h1',
                                    fontWeight: 400,
                                    fontSize: '24px',
                                    textAlign: 'center',
                                    lineHeight: '105%',
                                    linkStyle: {
                                        inherit: true,
                                        linkColor: '#0000ee',
                                        linkHoverColor: '#0000ee',
                                        linkUnderline: true,
                                        linkHoverUnderline: true,
                                    },
                                    hideDesktop: false,
                                    displayCondition: null,
                                    _meta: { htmlID: 'u_content_heading_1', htmlClassNames: 'u_content_heading' },
                                    selectable: true,
                                    draggable: true,
                                    duplicatable: true,
                                    deletable: true,
                                    hideable: true,
                                    text: '<span style="line-height: 21px;">Let\'s make it to straight A</span>',
                                    _languages: {},
                                },
                            },
                            {
                                id: 'KUYYV5PKT5',
                                type: 'heading',
                                values: {
                                    containerPadding: '60px 10px 10px',
                                    anchor: '',
                                    headingType: 'h1',
                                    fontSize: '20px',
                                    textAlign: 'left',
                                    lineHeight: '150%',
                                    linkStyle: {
                                        inherit: true,
                                        linkColor: '#0000ee',
                                        linkHoverColor: '#0000ee',
                                        linkUnderline: true,
                                        linkHoverUnderline: true,
                                    },
                                    hideDesktop: false,
                                    displayCondition: null,
                                    _meta: { htmlID: 'u_content_heading_3', htmlClassNames: 'u_content_heading' },
                                    selectable: true,
                                    draggable: true,
                                    duplicatable: true,
                                    deletable: true,
                                    hideable: true,
                                    text: '<span style="line-height: 33px;">Hey {{name}}</span>',
                                    _languages: {},
                                },
                            },
                            {
                                id: 'mgDoG12_qF',
                                type: 'text',
                                values: {
                                    containerPadding: '10px',
                                    anchor: '',
                                    fontSize: '16px',
                                    textAlign: 'left',
                                    lineHeight: '175%',
                                    linkStyle: {
                                        inherit: true,
                                        linkColor: '#0000ee',
                                        linkHoverColor: '#0000ee',
                                        linkUnderline: true,
                                        linkHoverUnderline: true,
                                    },
                                    hideDesktop: false,
                                    displayCondition: null,
                                    _meta: { htmlID: 'u_content_text_3', htmlClassNames: 'u_content_text' },
                                    selectable: true,
                                    draggable: true,
                                    duplicatable: true,
                                    deletable: true,
                                    hideable: true,
                                    text: '<p style="line-height: 175%;">We get it. Learning can be tough. Teachers can be rubbish. That\'s why a few 17-year-olds created this app to help millions of students do better at school. We\'ve been in your shoes too.</p>',
                                    _languages: {},
                                },
                            },
                            {
                                id: 'LS8e9n1c_y',
                                type: 'button',
                                values: {
                                    containerPadding: '10px',
                                    anchor: '',
                                    href: {
                                        name: 'web',
                                        values: { href: '', target: '_blank' },
                                        attrs: { href: '{{href}}', target: '{{target}}' },
                                    },
                                    buttonColors: {
                                        color: '#FFFFFF',
                                        backgroundColor: '#2563eb',
                                        hoverColor: '#FFFFFF',
                                        hoverBackgroundColor: '#3AAEE0',
                                    },
                                    size: { autoWidth: false, width: '50%' },
                                    fontWeight: 700,
                                    fontSize: '16px',
                                    textAlign: 'center',
                                    lineHeight: '150%',
                                    padding: '10px 20px',
                                    border: {},
                                    borderRadius: '4px',
                                    hideDesktop: false,
                                    displayCondition: null,
                                    _meta: { htmlID: 'u_content_button_1', htmlClassNames: 'u_content_button' },
                                    selectable: true,
                                    draggable: true,
                                    duplicatable: true,
                                    deletable: true,
                                    hideable: true,
                                    text: '<span style="line-height: 21px;">HELP ME</span>',
                                    _languages: {},
                                    calculatedWidth: 192,
                                    calculatedHeight: 44,
                                },
                            },
                        ],
                        values: {
                            backgroundColor: '#ffffff',
                            padding: '48px',
                            border: {},
                            borderRadius: '0px',
                            _meta: { htmlID: 'u_column_3', htmlClassNames: 'u_column' },
                        },
                    },
                ],
                values: {
                    displayCondition: null,
                    columns: false,
                    backgroundColor: '',
                    columnsBackgroundColor: '',
                    backgroundImage: {
                        url: '',
                        fullWidth: true,
                        repeat: 'no-repeat',
                        size: 'custom',
                        position: 'center',
                    },
                    padding: '0px',
                    anchor: '',
                    hideDesktop: false,
                    _meta: { htmlID: 'u_row_3', htmlClassNames: 'u_row' },
                    selectable: true,
                    draggable: true,
                    duplicatable: true,
                    deletable: true,
                    hideable: true,
                },
            },
            {
                id: 'DUSkBKyIoh',
                cells: [1],
                columns: [
                    {
                        id: 'SFMHB2UGOo',
                        contents: [
                            {
                                id: 'Tdc-DsijH4',
                                type: 'text',
                                values: {
                                    containerPadding: '10px',
                                    anchor: '',
                                    fontSize: '16px',
                                    textAlign: 'left',
                                    lineHeight: '175%',
                                    linkStyle: {
                                        inherit: true,
                                        linkColor: '#0000ee',
                                        linkHoverColor: '#0000ee',
                                        linkUnderline: true,
                                        linkHoverUnderline: true,
                                    },
                                    hideDesktop: false,
                                    displayCondition: null,
                                    _meta: { htmlID: 'u_content_text_5', htmlClassNames: 'u_content_text' },
                                    selectable: true,
                                    draggable: true,
                                    duplicatable: true,
                                    deletable: true,
                                    hideable: true,
                                    text: '<p style="line-height: 175%;">Here are our top tips to get back into a learning habit:</p>',
                                    _languages: {},
                                },
                            },
                            {
                                id: 'DtizhQqRt4',
                                type: 'heading',
                                values: {
                                    containerPadding: '28px 10px 10px',
                                    anchor: '',
                                    headingType: 'h1',
                                    fontWeight: 700,
                                    fontSize: '30px',
                                    textAlign: 'left',
                                    lineHeight: '115%',
                                    linkStyle: {
                                        inherit: true,
                                        linkColor: '#0000ee',
                                        linkHoverColor: '#0000ee',
                                        linkUnderline: true,
                                        linkHoverUnderline: true,
                                    },
                                    hideDesktop: false,
                                    displayCondition: null,
                                    _meta: { htmlID: 'u_content_heading_5', htmlClassNames: 'u_content_heading' },
                                    selectable: true,
                                    draggable: true,
                                    duplicatable: true,
                                    deletable: true,
                                    hideable: true,
                                    text: '<span style="line-height: 25.3px;">Start small</span>',
                                    _languages: {},
                                },
                            },
                            {
                                id: 'sLeEs0dGnt',
                                type: 'text',
                                values: {
                                    containerPadding: '12px 10px 10px',
                                    anchor: '',
                                    fontSize: '16px',
                                    textAlign: 'left',
                                    lineHeight: '175%',
                                    linkStyle: {
                                        inherit: true,
                                        linkColor: '#0000ee',
                                        linkHoverColor: '#0000ee',
                                        linkUnderline: true,
                                        linkHoverUnderline: true,
                                    },
                                    hideDesktop: false,
                                    displayCondition: null,
                                    _meta: { htmlID: 'u_content_text_6', htmlClassNames: 'u_content_text' },
                                    selectable: true,
                                    draggable: true,
                                    duplicatable: true,
                                    deletable: true,
                                    hideable: true,
                                    text: '<p style="line-height: 175%;">Even 10 minutes of studying is better than nothing.</p>',
                                    _languages: {},
                                },
                            },
                            {
                                id: '_YxocIjF15',
                                type: 'heading',
                                values: {
                                    containerPadding: '28px 10px 10px',
                                    anchor: '',
                                    headingType: 'h1',
                                    fontWeight: 700,
                                    fontSize: '30px',
                                    textAlign: 'left',
                                    lineHeight: '115%',
                                    linkStyle: {
                                        inherit: true,
                                        linkColor: '#0000ee',
                                        linkHoverColor: '#0000ee',
                                        linkUnderline: true,
                                        linkHoverUnderline: true,
                                    },
                                    hideDesktop: false,
                                    displayCondition: null,
                                    _meta: { htmlID: 'u_content_heading_6', htmlClassNames: 'u_content_heading' },
                                    selectable: true,
                                    draggable: true,
                                    duplicatable: true,
                                    deletable: true,
                                    hideable: true,
                                    text: '<span style="line-height: 25.3px;">Use Knowunity</span>',
                                    _languages: {},
                                },
                            },
                            {
                                id: '9zq3MmoD0q',
                                type: 'text',
                                values: {
                                    containerPadding: '12px 10px 10px',
                                    anchor: '',
                                    fontSize: '16px',
                                    textAlign: 'left',
                                    lineHeight: '175%',
                                    linkStyle: {
                                        inherit: true,
                                        linkColor: '#0000ee',
                                        linkHoverColor: '#0000ee',
                                        linkUnderline: true,
                                        linkHoverUnderline: true,
                                    },
                                    hideDesktop: false,
                                    displayCondition: null,
                                    _meta: { htmlID: 'u_content_text_7', htmlClassNames: 'u_content_text' },
                                    selectable: true,
                                    draggable: true,
                                    duplicatable: true,
                                    deletable: true,
                                    hideable: true,
                                    text: '<p style="line-height: 175%;">Access content that’s easy to understand and remember.</p>',
                                    _languages: {},
                                },
                            },
                            {
                                id: 'VPuwRe37w6',
                                type: 'heading',
                                values: {
                                    containerPadding: '28px 10px 10px',
                                    anchor: '',
                                    headingType: 'h1',
                                    fontWeight: 700,
                                    fontSize: '30px',
                                    textAlign: 'left',
                                    lineHeight: '115%',
                                    linkStyle: {
                                        inherit: true,
                                        linkColor: '#0000ee',
                                        linkHoverColor: '#0000ee',
                                        linkUnderline: true,
                                        linkHoverUnderline: true,
                                    },
                                    hideDesktop: false,
                                    displayCondition: null,
                                    _meta: { htmlID: 'u_content_heading_7', htmlClassNames: 'u_content_heading' },
                                    selectable: true,
                                    draggable: true,
                                    duplicatable: true,
                                    deletable: true,
                                    hideable: true,
                                    text: '<span style="line-height: 25.3px;">Reward yourself</span>',
                                    _languages: {},
                                },
                            },
                            {
                                id: 'ToxVPYSXoj',
                                type: 'text',
                                values: {
                                    containerPadding: '12px 10px 10px',
                                    anchor: '',
                                    fontSize: '16px',
                                    textAlign: 'left',
                                    lineHeight: '175%',
                                    linkStyle: {
                                        inherit: true,
                                        linkColor: '#0000ee',
                                        linkHoverColor: '#0000ee',
                                        linkUnderline: true,
                                        linkHoverUnderline: true,
                                    },
                                    hideDesktop: false,
                                    displayCondition: null,
                                    _meta: { htmlID: 'u_content_text_8', htmlClassNames: 'u_content_text' },
                                    selectable: true,
                                    draggable: true,
                                    duplicatable: true,
                                    deletable: true,
                                    hideable: true,
                                    text: '<p style="line-height: 175%;">Take breaks, eat chocolate and do something you enjoy after studying.</p>',
                                    _languages: {},
                                },
                            },
                            {
                                id: 'vC6TAX71td',
                                type: 'heading',
                                values: {
                                    containerPadding: '28px 10px 10px',
                                    anchor: '',
                                    headingType: 'h1',
                                    fontWeight: 700,
                                    fontSize: '30px',
                                    textAlign: 'left',
                                    lineHeight: '115%',
                                    linkStyle: {
                                        inherit: true,
                                        linkColor: '#0000ee',
                                        linkHoverColor: '#0000ee',
                                        linkUnderline: true,
                                        linkHoverUnderline: true,
                                    },
                                    hideDesktop: false,
                                    displayCondition: null,
                                    _meta: { htmlID: 'u_content_heading_8', htmlClassNames: 'u_content_heading' },
                                    selectable: true,
                                    draggable: true,
                                    duplicatable: true,
                                    deletable: true,
                                    hideable: true,
                                    text: '<span>Prepare an ideal study environment</span>',
                                    _languages: {},
                                },
                            },
                            {
                                id: 'iS3P2w2ego',
                                type: 'text',
                                values: {
                                    containerPadding: '12px 10px 10px',
                                    anchor: '',
                                    fontSize: '16px',
                                    textAlign: 'left',
                                    lineHeight: '175%',
                                    linkStyle: {
                                        inherit: true,
                                        linkColor: '#0000ee',
                                        linkHoverColor: '#0000ee',
                                        linkUnderline: true,
                                        linkHoverUnderline: true,
                                    },
                                    hideDesktop: false,
                                    displayCondition: null,
                                    _meta: { htmlID: 'u_content_text_9', htmlClassNames: 'u_content_text' },
                                    selectable: true,
                                    draggable: true,
                                    duplicatable: true,
                                    deletable: true,
                                    hideable: true,
                                    text: '<p style="line-height: 175%;">Whether it’s quiet or with background lofi beats.</p>',
                                    _languages: {},
                                },
                            },
                            {
                                id: '-Pxn2AQwW2',
                                type: 'heading',
                                values: {
                                    containerPadding: '28px 10px 10px',
                                    anchor: '',
                                    headingType: 'h1',
                                    fontWeight: 700,
                                    fontSize: '30px',
                                    textAlign: 'left',
                                    lineHeight: '115%',
                                    linkStyle: {
                                        inherit: true,
                                        linkColor: '#0000ee',
                                        linkHoverColor: '#0000ee',
                                        linkUnderline: true,
                                        linkHoverUnderline: true,
                                    },
                                    hideDesktop: false,
                                    displayCondition: null,
                                    _meta: { htmlID: 'u_content_heading_4', htmlClassNames: 'u_content_heading' },
                                    selectable: true,
                                    draggable: true,
                                    duplicatable: true,
                                    deletable: true,
                                    hideable: true,
                                    text: '<span>Summarise</span>',
                                    _languages: {},
                                },
                            },
                            {
                                id: 'xbg6Jq0BoS',
                                type: 'text',
                                values: {
                                    containerPadding: '12px 10px 10px',
                                    anchor: '',
                                    fontSize: '16px',
                                    textAlign: 'left',
                                    lineHeight: '175%',
                                    linkStyle: {
                                        inherit: true,
                                        linkColor: '#0000ee',
                                        linkHoverColor: '#0000ee',
                                        linkUnderline: true,
                                        linkHoverUnderline: true,
                                    },
                                    hideDesktop: false,
                                    displayCondition: null,
                                    _meta: { htmlID: 'u_content_text_4', htmlClassNames: 'u_content_text' },
                                    selectable: true,
                                    draggable: true,
                                    duplicatable: true,
                                    deletable: true,
                                    hideable: true,
                                    text: '<p style="line-height: 175%;">Make mind maps, try flashcards, or note down the key points.</p>',
                                    _languages: {},
                                },
                            },
                        ],
                        values: {
                            backgroundColor: '#dbeafe',
                            padding: '48px',
                            border: {},
                            borderRadius: '0px',
                            _meta: { htmlID: 'u_column_4', htmlClassNames: 'u_column' },
                        },
                    },
                ],
                values: {
                    displayCondition: null,
                    columns: false,
                    backgroundColor: '',
                    columnsBackgroundColor: '',
                    backgroundImage: {
                        url: '',
                        fullWidth: true,
                        repeat: 'no-repeat',
                        size: 'custom',
                        position: 'center',
                    },
                    padding: '0px',
                    anchor: '',
                    hideDesktop: false,
                    _meta: { htmlID: 'u_row_4', htmlClassNames: 'u_row' },
                    selectable: true,
                    draggable: true,
                    duplicatable: true,
                    deletable: true,
                    hideable: true,
                },
            },
            {
                id: 'Ek_erVbVoS',
                cells: [1],
                columns: [
                    {
                        id: 'WHIGDzAYfT',
                        contents: [
                            {
                                id: 'w8jFm5V-YR',
                                type: 'text',
                                values: {
                                    containerPadding: '10px',
                                    anchor: '',
                                    fontSize: '16px',
                                    textAlign: 'left',
                                    lineHeight: '175%',
                                    linkStyle: {
                                        inherit: true,
                                        linkColor: '#0000ee',
                                        linkHoverColor: '#0000ee',
                                        linkUnderline: true,
                                        linkHoverUnderline: true,
                                    },
                                    hideDesktop: false,
                                    displayCondition: null,
                                    _meta: { htmlID: 'u_content_text_13', htmlClassNames: 'u_content_text' },
                                    selectable: true,
                                    draggable: true,
                                    duplicatable: true,
                                    deletable: true,
                                    hideable: true,
                                    text: '<p style="line-height: 175%;">Now, let\'s do it. You can ace this year, even if you think you hate studying. We believe in you. Ready to show those teachers what you\'re capable of?</p>',
                                    _languages: {},
                                },
                            },
                            {
                                id: '0VHHSwUbLC',
                                type: 'button',
                                values: {
                                    containerPadding: '10px',
                                    anchor: '',
                                    href: {
                                        name: 'web',
                                        values: { href: '', target: '_blank' },
                                        attrs: { href: '{{href}}', target: '{{target}}' },
                                    },
                                    buttonColors: {
                                        color: '#FFFFFF',
                                        backgroundColor: '#2563eb',
                                        hoverColor: '#FFFFFF',
                                        hoverBackgroundColor: '#3AAEE0',
                                    },
                                    size: { autoWidth: false, width: '50%' },
                                    fontWeight: 700,
                                    fontSize: '16px',
                                    textAlign: 'center',
                                    lineHeight: '150%',
                                    padding: '10px 20px',
                                    border: {},
                                    borderRadius: '4px',
                                    hideDesktop: false,
                                    displayCondition: null,
                                    _meta: { htmlID: 'u_content_button_2', htmlClassNames: 'u_content_button' },
                                    selectable: true,
                                    draggable: true,
                                    duplicatable: true,
                                    deletable: true,
                                    hideable: true,
                                    text: '<span style="line-height: 24px;">ACE THIS YEAR</span>',
                                    _languages: {},
                                    calculatedWidth: 192,
                                    calculatedHeight: 44,
                                },
                            },
                        ],
                        values: {
                            backgroundColor: '#ffffff',
                            padding: '48px',
                            border: {},
                            borderRadius: '0px',
                            _meta: { htmlID: 'u_column_5', htmlClassNames: 'u_column' },
                        },
                    },
                ],
                values: {
                    displayCondition: null,
                    columns: false,
                    backgroundColor: '',
                    columnsBackgroundColor: '',
                    backgroundImage: {
                        url: '',
                        fullWidth: true,
                        repeat: 'no-repeat',
                        size: 'custom',
                        position: 'center',
                    },
                    padding: '0px',
                    anchor: '',
                    hideDesktop: false,
                    _meta: { htmlID: 'u_row_5', htmlClassNames: 'u_row' },
                    selectable: true,
                    draggable: true,
                    duplicatable: true,
                    deletable: true,
                    hideable: true,
                },
            },
            {
                id: 'LXMv68AbCZ',
                cells: [1],
                columns: [
                    {
                        id: 'N3HGzkP9S5',
                        contents: [
                            {
                                id: 'g8j9b1j0ws',
                                type: 'image',
                                values: {
                                    containerPadding: '10px',
                                    anchor: '',
                                    src: {
                                        url: 'https://assets.unlayer.com/projects/0/1725893209663-03_Flask.png',
                                        width: 290,
                                        height: 290,
                                        contentType: 'image/png',
                                        size: 41192,
                                    },
                                    textAlign: 'center',
                                    altText: '',
                                    action: { name: 'web', values: { href: '', target: '_blank' } },
                                    hideDesktop: false,
                                    displayCondition: null,
                                    _meta: { htmlID: 'u_content_image_2', htmlClassNames: 'u_content_image' },
                                    selectable: true,
                                    draggable: true,
                                    duplicatable: true,
                                    deletable: true,
                                    hideable: true,
                                    pending: false,
                                },
                            },
                            {
                                id: 'i948aSagjb',
                                type: 'heading',
                                values: {
                                    containerPadding: '0px 10px 10px',
                                    anchor: '',
                                    headingType: 'h1',
                                    fontWeight: 700,
                                    fontSize: '30px',
                                    textAlign: 'left',
                                    lineHeight: '115%',
                                    linkStyle: {
                                        inherit: true,
                                        linkColor: '#0000ee',
                                        linkHoverColor: '#0000ee',
                                        linkUnderline: true,
                                        linkHoverUnderline: true,
                                    },
                                    hideDesktop: false,
                                    displayCondition: null,
                                    _meta: { htmlID: 'u_content_heading_12', htmlClassNames: 'u_content_heading' },
                                    selectable: true,
                                    draggable: true,
                                    duplicatable: true,
                                    deletable: true,
                                    hideable: true,
                                    text: '<span style="line-height: 34.5px;">Back to school</span>',
                                    _languages: {},
                                },
                            },
                            {
                                id: '4aK5oChSkN',
                                type: 'text',
                                values: {
                                    containerPadding: '10px',
                                    anchor: '',
                                    fontSize: '16px',
                                    textAlign: 'left',
                                    lineHeight: '175%',
                                    linkStyle: {
                                        inherit: true,
                                        linkColor: '#0000ee',
                                        linkHoverColor: '#0000ee',
                                        linkUnderline: true,
                                        linkHoverUnderline: true,
                                    },
                                    hideDesktop: false,
                                    displayCondition: null,
                                    _meta: { htmlID: 'u_content_text_11', htmlClassNames: 'u_content_text' },
                                    selectable: true,
                                    draggable: true,
                                    duplicatable: true,
                                    deletable: true,
                                    hideable: true,
                                    text: '<p style="line-height: 175%;">Lorem ipsum dolor sit amet consectetur. Tellus lorem metus ut phasellus scelerisque ultrices faucibus scelerisque purus. Pulvinar sit diam enim aliquam lectus. Augue in fermentum ut.</p>',
                                    _languages: {},
                                },
                            },
                            {
                                id: 'dHJMsMqNEU',
                                type: 'image',
                                values: {
                                    containerPadding: '10px',
                                    anchor: '',
                                    src: {
                                        url: 'https://assets.unlayer.com/projects/0/1725893200204-image%2032.png',
                                        width: 492,
                                        height: 273,
                                        contentType: 'image/png',
                                        size: 183040,
                                    },
                                    textAlign: 'center',
                                    altText: '',
                                    action: { name: 'web', values: { href: '', target: '_blank' } },
                                    hideDesktop: false,
                                    displayCondition: null,
                                    _meta: { htmlID: 'u_content_image_3', htmlClassNames: 'u_content_image' },
                                    selectable: true,
                                    draggable: true,
                                    duplicatable: true,
                                    deletable: true,
                                    hideable: true,
                                    pending: false,
                                },
                            },
                        ],
                        values: {
                            backgroundColor: '#ffffff',
                            padding: '48px',
                            border: {},
                            borderRadius: '0px',
                            _meta: { htmlID: 'u_column_6', htmlClassNames: 'u_column' },
                        },
                    },
                ],
                values: {
                    displayCondition: null,
                    columns: false,
                    backgroundColor: '',
                    columnsBackgroundColor: '',
                    backgroundImage: {
                        url: '',
                        fullWidth: true,
                        repeat: 'no-repeat',
                        size: 'custom',
                        position: 'center',
                    },
                    padding: '0px',
                    anchor: '',
                    hideDesktop: false,
                    _meta: { htmlID: 'u_row_6', htmlClassNames: 'u_row' },
                    selectable: true,
                    draggable: true,
                    duplicatable: true,
                    deletable: true,
                    hideable: true,
                },
            },
            {
                id: '6BLRyAscEA',
                cells: [41.6, 58.4],
                columns: [
                    {
                        id: 'vLQxkLo24S',
                        contents: [
                            {
                                id: 'dS9NEaLwHp',
                                type: 'image',
                                values: {
                                    containerPadding: '10px',
                                    anchor: '',
                                    src: {
                                        url: 'https://assets.unlayer.com/projects/0/1725893181467-Phone.png',
                                        width: 150,
                                        height: 300,
                                        contentType: 'image/png',
                                        size: 4479,
                                    },
                                    textAlign: 'center',
                                    altText: '',
                                    action: { name: 'web', values: { href: '', target: '_blank' } },
                                    hideDesktop: false,
                                    displayCondition: null,
                                    _meta: { htmlID: 'u_content_image_7', htmlClassNames: 'u_content_image' },
                                    selectable: true,
                                    draggable: true,
                                    duplicatable: true,
                                    deletable: true,
                                    hideable: true,
                                    pending: false,
                                },
                            },
                        ],
                        values: {
                            backgroundColor: '#ffffff',
                            padding: '33px',
                            border: {},
                            borderRadius: '0px',
                            _meta: { htmlID: 'u_column_7', htmlClassNames: 'u_column' },
                        },
                    },
                    {
                        id: 'b7h-UOzVhG',
                        contents: [
                            {
                                id: 'N7WY0qc_Ws',
                                type: 'heading',
                                values: {
                                    containerPadding: '10px',
                                    anchor: '',
                                    headingType: 'h1',
                                    fontWeight: 700,
                                    fontSize: '30px',
                                    textAlign: 'left',
                                    lineHeight: '115%',
                                    linkStyle: {
                                        inherit: true,
                                        linkColor: '#0000ee',
                                        linkHoverColor: '#0000ee',
                                        linkUnderline: true,
                                        linkHoverUnderline: true,
                                    },
                                    hideDesktop: false,
                                    displayCondition: null,
                                    _meta: { htmlID: 'u_content_heading_13', htmlClassNames: 'u_content_heading' },
                                    selectable: true,
                                    draggable: true,
                                    duplicatable: true,
                                    deletable: true,
                                    hideable: true,
                                    text: '<span>Find better notes</span>',
                                    _languages: {},
                                },
                            },
                            {
                                id: 'l5HuoxMN1X',
                                type: 'text',
                                values: {
                                    containerPadding: '10px',
                                    anchor: '',
                                    fontSize: '16px',
                                    textAlign: 'left',
                                    lineHeight: '175%',
                                    linkStyle: {
                                        inherit: true,
                                        linkColor: '#0000ee',
                                        linkHoverColor: '#0000ee',
                                        linkUnderline: true,
                                        linkHoverUnderline: true,
                                    },
                                    hideDesktop: false,
                                    displayCondition: null,
                                    _meta: { htmlID: 'u_content_text_14', htmlClassNames: 'u_content_text' },
                                    selectable: true,
                                    draggable: true,
                                    duplicatable: true,
                                    deletable: true,
                                    hideable: true,
                                    text: '<p style="line-height: 175%;">Lorem ipsum dolor sit amet consectetur. Tellus lorem metus ut phasellus scelerisque ultrices faucibus scelerisque purus. Pulvinar sit diam enim aliquam lectus. Augue in fermentum ut.</p>',
                                    _languages: {},
                                },
                            },
                        ],
                        values: {
                            backgroundColor: '#ffffff',
                            padding: '33px',
                            border: {},
                            borderRadius: '0px',
                            _meta: { htmlID: 'u_column_8', htmlClassNames: 'u_column' },
                        },
                    },
                ],
                values: {
                    displayCondition: null,
                    columns: false,
                    backgroundColor: '',
                    columnsBackgroundColor: '',
                    backgroundImage: {
                        url: '',
                        fullWidth: true,
                        repeat: 'no-repeat',
                        size: 'custom',
                        position: 'center',
                    },
                    padding: '0px',
                    anchor: '',
                    hideDesktop: false,
                    _meta: { htmlID: 'u_row_7', htmlClassNames: 'u_row' },
                    selectable: true,
                    draggable: true,
                    duplicatable: true,
                    deletable: true,
                    hideable: true,
                },
            },
            {
                id: 'sJFveWc3b9',
                cells: [58, 42],
                columns: [
                    {
                        id: 'Iv_pseYRb2',
                        contents: [
                            {
                                id: 'pnUHfRNCFi',
                                type: 'heading',
                                values: {
                                    containerPadding: '10px',
                                    anchor: '',
                                    headingType: 'h1',
                                    fontWeight: 700,
                                    fontSize: '30px',
                                    textAlign: 'left',
                                    lineHeight: '115%',
                                    linkStyle: {
                                        inherit: true,
                                        linkColor: '#0000ee',
                                        linkHoverColor: '#0000ee',
                                        linkUnderline: true,
                                        linkHoverUnderline: true,
                                    },
                                    hideDesktop: false,
                                    displayCondition: null,
                                    _meta: { htmlID: 'u_content_heading_14', htmlClassNames: 'u_content_heading' },
                                    selectable: true,
                                    draggable: true,
                                    duplicatable: true,
                                    deletable: true,
                                    hideable: true,
                                    text: '<span>Chat with an AI assistant</span>',
                                    _languages: {},
                                },
                            },
                            {
                                id: 'k1kDaZeQ8X',
                                type: 'text',
                                values: {
                                    containerPadding: '10px',
                                    anchor: '',
                                    fontSize: '16px',
                                    textAlign: 'left',
                                    lineHeight: '175%',
                                    linkStyle: {
                                        inherit: true,
                                        linkColor: '#0000ee',
                                        linkHoverColor: '#0000ee',
                                        linkUnderline: true,
                                        linkHoverUnderline: true,
                                    },
                                    hideDesktop: false,
                                    displayCondition: null,
                                    _meta: { htmlID: 'u_content_text_15', htmlClassNames: 'u_content_text' },
                                    selectable: true,
                                    draggable: true,
                                    duplicatable: true,
                                    deletable: true,
                                    hideable: true,
                                    text: '<p style="line-height: 175%;">Lorem ipsum dolor sit amet consectetur. Tellus lorem metus ut phasellus scelerisque ultrices faucibus scelerisque purus. Pulvinar sit diam enim aliquam lectus. Augue in fermentum ut.</p>',
                                    _languages: {},
                                },
                            },
                        ],
                        values: {
                            backgroundColor: '#ffffff',
                            padding: '33px',
                            border: {},
                            borderRadius: '0px',
                            _meta: { htmlID: 'u_column_9', htmlClassNames: 'u_column' },
                        },
                    },
                    {
                        id: '_gUIS6hymJ',
                        contents: [
                            {
                                id: 'qFAov-Pl17',
                                type: 'image',
                                values: {
                                    containerPadding: '10px',
                                    anchor: '',
                                    src: {
                                        url: 'https://assets.unlayer.com/projects/0/1725893173977-Phone.png',
                                        width: 150,
                                        height: 300,
                                        contentType: 'image/png',
                                        size: 4479,
                                    },
                                    textAlign: 'center',
                                    altText: '',
                                    action: { name: 'web', values: { href: '', target: '_blank' } },
                                    hideDesktop: false,
                                    displayCondition: null,
                                    _meta: { htmlID: 'u_content_image_10', htmlClassNames: 'u_content_image' },
                                    selectable: true,
                                    draggable: true,
                                    duplicatable: true,
                                    deletable: true,
                                    hideable: true,
                                    pending: false,
                                },
                            },
                        ],
                        values: {
                            backgroundColor: '#ffffff',
                            padding: '33px',
                            border: {},
                            borderRadius: '0px',
                            _meta: { htmlID: 'u_column_10', htmlClassNames: 'u_column' },
                        },
                    },
                ],
                values: {
                    displayCondition: null,
                    columns: false,
                    backgroundColor: '',
                    columnsBackgroundColor: '',
                    backgroundImage: {
                        url: '',
                        fullWidth: true,
                        repeat: 'no-repeat',
                        size: 'custom',
                        position: 'center',
                    },
                    padding: '0px',
                    anchor: '',
                    hideDesktop: false,
                    _meta: { htmlID: 'u_row_8', htmlClassNames: 'u_row' },
                    selectable: true,
                    draggable: true,
                    duplicatable: true,
                    deletable: true,
                    hideable: true,
                },
            },
            {
                id: 'TolrOVgdiE',
                cells: [1],
                columns: [
                    {
                        id: 'Ea0-FMDUHO',
                        contents: [
                            {
                                id: 'j7AzVwAFbh',
                                type: 'text',
                                values: {
                                    containerPadding: '10px',
                                    anchor: '',
                                    fontSize: '16px',
                                    textAlign: 'left',
                                    lineHeight: '175%',
                                    linkStyle: {
                                        inherit: true,
                                        linkColor: '#0000ee',
                                        linkHoverColor: '#0000ee',
                                        linkUnderline: true,
                                        linkHoverUnderline: true,
                                    },
                                    hideDesktop: false,
                                    displayCondition: null,
                                    _meta: { htmlID: 'u_content_text_18', htmlClassNames: 'u_content_text' },
                                    selectable: true,
                                    draggable: true,
                                    duplicatable: true,
                                    deletable: true,
                                    hideable: true,
                                    text: '<p style="line-height: 175%;">Take care of yourself this year,</p>',
                                    _languages: {},
                                },
                            },
                            {
                                id: 'yQlN0gy5op',
                                type: 'image',
                                values: {
                                    containerPadding: '10px',
                                    anchor: '',
                                    src: {
                                        url: 'https://assets.unlayer.com/projects/0/1725893192115-Group%2039922.png',
                                        width: 178,
                                        height: 81,
                                        contentType: 'image/png',
                                        size: 5802,
                                    },
                                    textAlign: 'center',
                                    altText: '',
                                    action: { name: 'web', values: { href: '', target: '_blank' } },
                                    hideDesktop: false,
                                    displayCondition: null,
                                    _meta: { htmlID: 'u_content_image_12', htmlClassNames: 'u_content_image' },
                                    selectable: true,
                                    draggable: true,
                                    duplicatable: true,
                                    deletable: true,
                                    hideable: true,
                                    pending: false,
                                },
                            },
                            {
                                id: 'GVvzfp0DL-',
                                type: 'text',
                                values: {
                                    containerPadding: '10px',
                                    anchor: '',
                                    fontSize: '16px',
                                    textAlign: 'left',
                                    lineHeight: '175%',
                                    linkStyle: {
                                        inherit: true,
                                        linkColor: '#0000ee',
                                        linkHoverColor: '#0000ee',
                                        linkUnderline: true,
                                        linkHoverUnderline: true,
                                    },
                                    hideDesktop: false,
                                    displayCondition: null,
                                    _meta: { htmlID: 'u_content_text_17', htmlClassNames: 'u_content_text' },
                                    selectable: true,
                                    draggable: true,
                                    duplicatable: true,
                                    deletable: true,
                                    hideable: true,
                                    text: '<p style="line-height: 175%;"><em>P.S. Nothing beats that feeling of incredible grades</em></p>',
                                    _languages: {},
                                },
                            },
                            {
                                id: '_RgaN-bsZ5',
                                type: 'text',
                                values: {
                                    containerPadding: '10px',
                                    anchor: '',
                                    fontSize: '12px',
                                    textAlign: 'center',
                                    lineHeight: '150%',
                                    linkStyle: {
                                        inherit: true,
                                        linkColor: '#0000ee',
                                        linkHoverColor: '#0000ee',
                                        linkUnderline: true,
                                        linkHoverUnderline: true,
                                    },
                                    hideDesktop: false,
                                    displayCondition: null,
                                    _meta: { htmlID: 'u_content_text_19', htmlClassNames: 'u_content_text' },
                                    selectable: true,
                                    draggable: true,
                                    duplicatable: true,
                                    deletable: true,
                                    hideable: true,
                                    text: '<p style="line-height: 150%;">Not interested any more? You can unsubscribe <a rel="noopener" href="{{unsubscribeLink}}" target="_blank" data-u-link-value="eyJuYW1lIjoid2ViIiwiYXR0cnMiOnsiaHJlZiI6Int7aHJlZn19IiwidGFyZ2V0Ijoie3t0YXJnZXR9fSJ9LCJ2YWx1ZXMiOnsiaHJlZiI6Int7dW5zdWJzY3JpYmVMaW5rfX0iLCJ0YXJnZXQiOiJfYmxhbmsifX0=">here</a>.<br />Knowunity GmbH, Julie-Wolfthorn-Str. 10115 Berlin, Germany.</p>',
                                    _languages: {},
                                },
                            },
                        ],
                        values: {
                            backgroundColor: '#ffffff',
                            padding: '48px',
                            border: {},
                            borderRadius: '0px',
                            _meta: { htmlID: 'u_column_13', htmlClassNames: 'u_column' },
                        },
                    },
                ],
                values: {
                    displayCondition: null,
                    columns: false,
                    backgroundColor: '',
                    columnsBackgroundColor: '',
                    backgroundImage: {
                        url: '',
                        fullWidth: true,
                        repeat: 'no-repeat',
                        size: 'custom',
                        position: 'center',
                    },
                    padding: '0px',
                    anchor: '',
                    hideDesktop: false,
                    _meta: { htmlID: 'u_row_10', htmlClassNames: 'u_row' },
                    selectable: true,
                    draggable: true,
                    duplicatable: true,
                    deletable: true,
                    hideable: true,
                },
            },
        ],
        headers: [],
        footers: [],
        values: {
            popupPosition: 'center',
            popupWidth: '600px',
            popupHeight: 'auto',
            borderRadius: '10px',
            contentAlign: 'center',
            contentVerticalAlign: 'center',
            contentWidth: '500px',
            fontFamily: { label: 'Arial', value: 'arial,helvetica,sans-serif' },
            textColor: '#000000',
            popupBackgroundColor: '#FFFFFF',
            popupBackgroundImage: {
                url: '',
                fullWidth: true,
                repeat: 'no-repeat',
                size: 'cover',
                position: 'top-center',
                customPosition: ['50%', '0%'],
            },
            popupOverlay_backgroundColor: 'rgba(0, 0, 0, 0.1)',
            popupCloseButton_position: 'top-right',
            popupCloseButton_backgroundColor: '#DDDDDD',
            popupCloseButton_iconColor: '#000000',
            popupCloseButton_borderRadius: '0px',
            popupCloseButton_margin: '0px',
            popupCloseButton_action: {
                name: 'close_popup',
                attrs: { onClick: "document.querySelector('.u-popup-container').style.display = 'none';" },
            },
            language: {},
            backgroundColor: '#65a0f1',
            preheaderText: '',
            linkStyle: {
                body: true,
                linkColor: '#2563eb',
                linkHoverColor: '#0000ee',
                linkUnderline: false,
                linkHoverUnderline: true,
                inherit: false,
            },
            backgroundImage: {
                url: '',
                fullWidth: true,
                repeat: 'no-repeat',
                size: 'custom',
                position: 'top-center',
                customPosition: ['50%', '0%'],
            },
            _meta: { htmlID: 'u_body', htmlClassNames: 'u_body' },
        },
    },
    schemaVersion: 16,
})

export const returnCategoryDescription = (category: EmailCategories) => {
    switch (category) {
        case EmailCategories.Feedback:
            return 'Send form to ask users about their feedback'
        case EmailCategories.Knower:
            return 'Information for knowers'
        case EmailCategories.Advertisement:
            return 'Advertisement from external partners (mostly for sales team)'
        case EmailCategories.FeatureAnnouncement:
            return 'Information about new features of Knowunity'
        default:
            return ''
    }
}

export function validateEmail(email: string): boolean {
    const emailWithoutWhitespace = email.replace(/\s/g, '')
    const re =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    return re.test(String(emailWithoutWhitespace).toLowerCase())
}
